import React from "react";
import { Button, Card, Col, Flex, Modal, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import paths from "../../../../constants/routePaths";

interface IProps {
  open: boolean;
  numberOfQuestions: number;
  resultInPercent: string;
  numberOfCorrectAnswers: number;
}

const ResultsModal = ({
  open,
  numberOfQuestions,
  resultInPercent,
  numberOfCorrectAnswers,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <Modal open={open} closeIcon footer={[]}>
      <Flex vertical gap="middle">
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Test Natijalari
        </Typography.Title>
        <Card>
          <Row gutter={[16, 16]} justify="center">
            <Col span={24} sm={12}>
              <Flex vertical gap="small" align="center">
                <Typography>To'g'ri javoblar</Typography>
                <Typography.Title level={2}>
                  {numberOfCorrectAnswers}/{numberOfQuestions}
                </Typography.Title>
              </Flex>
            </Col>
            <Col span={24} sm={12}>
              <Flex vertical gap="small" align="center">
                <Typography>Natija %</Typography>
                <Typography.Title level={2}>
                  {resultInPercent}%
                </Typography.Title>
              </Flex>
            </Col>
          </Row>
        </Card>
        <Flex gap="middle">
          <Button onClick={() => navigate(paths.CONTESTS)} block>
            Testdan chiqish
          </Button>
          <Button onClick={() => navigate(paths.RESULTS)} type="primary" block>
            Natijalar jadvali
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ResultsModal;
