import { lazy } from "react";
import paths from "../constants/routePaths";
import ContestInfo from "../pages/Contests/pages/Info";
import Test from "../pages/Test";

const TestResults = lazy(() => import("../pages/TestResults"));

export const ROUTES = [
  {
    path: paths.RESULTS,
    component: TestResults,
    exact: true,
  },
  {
    path: paths.CONTESTS + ":id",
    component: ContestInfo,
    exact: true,
  },
  {
    path: paths.TEST,
    component: Test,
    exact: true,
  },
];

export const HEADER_ROUTES = [
  {
    path: paths.MAIN,
    title: "Asosiy",
  },
  {
    path: paths.COURSES,
    title: "Kurslar",
  },
  {
    path: paths.ADDRESS,
    title: "Manzil",
  },
  {
    path: paths.ARTICLES,
    title: "Maqolalar",
  },
];

export const AUTH_ROUTES = [];
