import React from "react";
import { Flex, Spin } from "antd";

const InnerLoader = () => {
  return (
    <Flex
      justify="center"
      align="center"
      style={{ paddingTop: 20, paddingBottom: 20, marginTop: 30 }}
    >
      <Spin />
    </Flex>
  );
};

export default InnerLoader;
