import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Flex, Layout, Menu, Typography } from "antd";

import { HEADER_ROUTES } from "../routes";
import { StyledMain } from "./Main.style";
import { colors } from "../theme/variables";
import { useAppSelector } from "../hooks/redux";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import paths from "../constants/routePaths";

const { Header, Content } = Layout;

interface Props {
  children: ReactNode;
  containerBg?: string;
  hasContainerPadding?: boolean;
}

const MainLayout = ({
  children,
  containerBg,
  hasContainerPadding = true,
}: Props) => {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <StyledMain hasContainerPadding={hasContainerPadding}>
      <Layout>
        <Header className="layout__header">
          <Flex
            gap={16}
            align="center"
            justify="space-between"
            className="layout__header-inner"
          >
            <Link to={paths.MAIN} className="layout__header-logo">
              <Logo style={{ width: 141, height: 43 }} />
            </Link>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ flex: 1, justifyContent: "flex-end" }}
              items={HEADER_ROUTES.map((route) => ({
                key: route.path,
                label: <Link to={route.path}>{route.title}</Link>,
              }))}
            />
            <Button type="primary">Kursga yozilish</Button>
            {user?.id && (
              <Flex gap="small" align="center" justify="flex-end">
                <Avatar
                  src={user?.profilePhoto}
                  style={
                    !user?.profilePhoto
                      ? { background: colors.orange[100] }
                      : {}
                  }
                  className="layout__header-user-img"
                >
                  {user?.fullName[0]}
                </Avatar>
                <Typography.Text strong className="layout__header-user-name">
                  {user?.fullName}
                </Typography.Text>
              </Flex>
            )}
          </Flex>
        </Header>
        <Content className="layout__content">
          <div
            className="layout__content-container"
            style={{ background: containerBg || colors.dark[50] }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </StyledMain>
  );
};

export default MainLayout;
