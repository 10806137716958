import styled from "styled-components";
import { colors } from "../../../../theme/variables";

const StyledContestInfo = styled.div`
  .contest__img {
    height: 350px;
    object-fit: contain;
  }

  .contest__times-card {
    background-color: ${colors.blue[100]};
  }

  @media (max-width: 768px) {
    .contest__img {
      height: auto;
      object-fit: initial;
    }
  }
`;

export default StyledContestInfo;
