import React from "react";
import { Button, Flex, Modal, Typography } from "antd";

interface IProps {
  open: boolean;
  title?: string;
  description?: string;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
}: IProps) => {
  return (
    <Modal
      style={{ textAlign: "center" }}
      open={open}
      onCancel={onClose}
      footer={[]}
    >
      <Flex vertical gap="middle">
        <Flex vertical gap={2}>
          <Typography.Title level={3}>
            {title || "Testni yakunlashni xohlaysizmi?"}
          </Typography.Title>
          <Typography.Text>
            {description ||
              "Sinovni tugatsangiz, qayta ishlay olmasligingiz mumkin."}
          </Typography.Text>
        </Flex>
        <Flex gap="middle">
          <Button onClick={onClose} block>
            Bekor qilish
          </Button>
          <Button onClick={onConfirm} type="primary" block>
            Ishonchim komil
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ConfirmModal;
