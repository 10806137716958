import React from "react";
import { Flex, Form, Typography } from "antd";
import StyledTestListItem from "./TestListItem.style";

interface Props {
  index: number;
  id: number | string;
  form: any;
}

const TestListItem = ({ index, id, form }: Props) => {
  const isSelected = Form.useWatch(id, form);

  return (
    <StyledTestListItem selected={isSelected}>
      <a href={`#test-${index}`}>
        <Flex align="center" justify="center" className="item">
          <Typography.Text>{index}</Typography.Text>
        </Flex>
      </a>
    </StyledTestListItem>
  );
};

export default TestListItem;
