import { Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "moment/min/locales.min";
import moment from "moment";
import jwtDecode from "jwt-decode";

import { ROUTES } from "./routes";
import paths from "./constants/routePaths";
import supabase from "./config/api";
import { logOut, signIn } from "./store/slices/auth";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

moment.locale("uz-latn");

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("u");

  const getUserById = async (id: string) => {
    const { data: usersData } = await supabase
      .from("TelegramUsers")
      .select("id, fullName, profilePhoto")
      .eq("id", id);

    return usersData?.length ? usersData[0] : {};
  };

  const checkUser = async () => {
    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        if (decodedToken?.id && decodedToken?.contestId) {
          const user = await getUserById(decodedToken?.id);
          if (Object.values(user)?.length) {
            dispatch(logOut());
            dispatch(signIn(user));
            navigate(paths.CONTESTS + decodedToken?.contestId);
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    checkUser();
  }, [token]);

  return (
    <Routes>
      {ROUTES.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<Spin />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
      <Route path="*" element={<Navigate to={paths.MAIN} replace />} />
    </Routes>
  );
};

export default App;
