import styled from "styled-components";
import { colors } from "../theme/variables";

export const StyledMain = styled.div<{ hasContainerPadding?: boolean }>`
  .layout__header {
    padding: 0;
  }

  .layout__header-inner {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0px 15px;
  }

  .layout__header-logo {
    display: flex;
    align-items: center;
  }

  .layout__header-user-name {
    color: ${colors.white[100]};
  }

  .layout__header-user-img {
    color: ${colors.white[100]};
  }

  .layout__content {
    padding: 50px 15px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  .layout__content-container {
    min-height: 73vh;
    border-radius: 6px;
    padding: ${(props) => (props.hasContainerPadding ? "24px" : "0px")};
  }

  .ant-menu-title-content {
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .layout__header {
      padding: 0 30px;
    }
    .layout__content {
      padding: 30px;
    }
    .layout__header-user-name {
      display: none;
    }
  }
`;
