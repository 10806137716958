import React from "react";
import { Col, Divider, Form, Radio, Space } from "antd";

import Title from "antd/es/typography/Title";

export interface TestItemProps {
  id: string;
  title: string;
  answers: {
    id: "a" | "b" | "c" | "d";
    title: string;
  }[];
  index: number;
}

const TestItem = ({ title, answers, id, index }: TestItemProps) => (
  <Col span={24} id={`test-${index}`}>
    <Title level={4}>
      {index}. {title}
    </Title>
    <Divider style={{ marginTop: 16, marginBottom: 16 }} />
    <Form.Item name={id}>
      <Radio.Group name={id}>
        <Space direction="vertical">
          {answers.map(({ id: optionId, title }) => (
            <Radio className="question__title" key={optionId} value={optionId}>
              {title}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  </Col>
);

export default TestItem;
