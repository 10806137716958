export const colors = {
  blue: {
    50: "#E6F4FF",
    100: "#1C32A0",
    200: "#273B9C",
  },
  dark: {
    50: "#1C1E2A",
    100: "#12141D",
  },
  white: {
    100: "#fff",
  },
  black: {
    50: "#00000050",
  },
  border: {
    100: "#FFFFFF2B",
  },
  orange: {
    100: "#f56a00",
  },
};
