import React, { useEffect, useState } from "react";
import { Button, Card, Col, Flex, Image, Row, Typography, message } from "antd";
import Title from "antd/es/typography/Title";
import MainLayout from "../../../../layout/Main";
import { useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../config/api";
import { ContestType } from "../../types";
import Countdown from "react-countdown";
import paths from "../../../../constants/routePaths";
import StyledContestInfo from "./Info.style";
import InnerLoader from "../../../../UI/InnerLoader";
import { useDispatch } from "react-redux";
import { setContest } from "../../../../store/slices/auth";
import { useAppSelector } from "../../../../hooks/redux";

const ContestInfo = () => {
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [contest, setContestData] = useState<ContestType>();
  const [isStarted, setIsStarted] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getContestById = async () => {
    setLoading(true);
    let { data: contestData } = await supabase
      .from("Contest")
      .select("*")
      .eq("id", id);

    setLoading(false);
    setContestData(contestData ? contestData[0] : {});
  };

  const handleBack = async () => {
    await supabase
      .from("Contest")
      .update({ isActive: false })
      .eq("id", id)
      .select();

    message.warning("Bu tanlov faol emas");
    navigate(paths.CONTESTS);
  };

  const handleAttend = () => {
    dispatch(setContest(contest));
    navigate(paths.TEST);
  };

  const handleNavigateResults = () => {
    dispatch(setContest(contest));
    navigate(paths.RESULTS);
  };

  useEffect(() => {
    getContestById();
    setIsStarted(
      new Date().getTime() >
        new Date(contest?.willStartAt || new Date()).getTime()
    );
  }, [id]);

  const isDisabled =
    !contest?.isActive || contest?.isCompleted || !isStarted || !user?.id;

  return (
    <MainLayout>
      {loading ? (
        <InnerLoader />
      ) : (
        <StyledContestInfo>
          <Flex gap={40} vertical>
            <Flex vertical gap="large">
              <Title level={2}>{contest?.title}</Title>
              {contest?.imageUrl && (
                <Image src={contest?.imageUrl} className="contest__img" />
              )}
              <Typography.Text>{contest?.description}</Typography.Text>
            </Flex>
            <Card className="contest__times-card" bordered={false}>
              <Row gutter={[16, 16]} justify="center">
                {isStarted ? (
                  <>
                    <Col span={24} md={8} lg={6}>
                      <Flex vertical gap="small" align="center">
                        <Typography>Savollar Soni</Typography>
                        <Title level={2}>
                          {contest?.numberOfTestsToGenerate}
                        </Title>
                      </Flex>
                    </Col>
                    <Col span={24} md={8} lg={6}>
                      <Flex vertical gap="small" align="center">
                        <Typography>Testni bajarish vaqti</Typography>
                        <Title level={2}>{contest?.duration}</Title>
                      </Flex>
                    </Col>
                    <Col span={24} md={8} lg={6}>
                      <Flex vertical gap="small" align="center">
                        <Typography>Test tugashiga qolgan vaqt</Typography>
                        <Title level={2}>
                          <Countdown
                            date={contest?.willEndAt}
                            onComplete={handleBack}
                          />
                        </Title>
                      </Flex>
                    </Col>
                  </>
                ) : (
                  <Col span={24} md={8} lg={6}>
                    <Flex vertical gap="small" align="center">
                      <Typography>Test boshlanishiga qolgan vaqt</Typography>
                      <Title level={2}>
                        <Countdown
                          date={contest?.willStartAt}
                          onComplete={() => setIsStarted(true)}
                        />
                      </Title>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Card>
            <Flex vertical align="center" gap="large">
              <Flex gap="middle" wrap="wrap" justify="center">
                <Button disabled={!isStarted} onClick={handleNavigateResults}>
                  Natijalar jadvali
                </Button>
                <Button
                  type="primary"
                  disabled={isDisabled}
                  onClick={handleAttend}
                >
                  Test topshirish
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </StyledContestInfo>
      )}
    </MainLayout>
  );
};

export default ContestInfo;
