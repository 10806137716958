import {
  Button,
  Col,
  Empty,
  Flex,
  Form,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import TestItem from "./components/TestItem";
import MainLayout from "../../layout/Main";
import { useEffect, useMemo, useState } from "react";
import supabase from "../../config/api";
import { useAppSelector } from "../../hooks/redux";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { setTestStartedAt } from "../../store/slices/auth";
import TestListItem from "./components/TestListItem";
import StyledTest from "./Test.style";
import ConfirmModal from "../../components/ConfirmModal";
import ResultsModal from "./components/ResultsModal";

const Test = () => {
  const {
    contest,
    user,
    testsStartedAt = {},
  } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [tests, setTests] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isCompleteModalVisible, setIsCompleteModalVisible] =
    useState<boolean>(false);
  const [isResultsModalVisible, setIsResultsModalVisible] =
    useState<boolean>(false);
  const [results, setResults] = useState<any>({});

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const questionAnswers = useMemo(() => {
    let answers: { [x: string]: string } = {};
    tests.forEach(({ id, correctAnswer }) => (answers[id] = correctAnswer));
    return answers;
  }, [tests]);

  const contestDuration = useMemo(() => {
    const date = testsStartedAt[contest?.id]
      ? new Date(testsStartedAt[contest?.id])
      : new Date();
    const duration = contest?.duration?.split(":");
    const hours = date.getHours() + parseInt(duration[0]);
    const minutes = date.getMinutes() + parseInt(duration[1]);
    const seconds = date.getSeconds() + parseInt(duration[2]);

    return date.setHours(hours, minutes, seconds);
  }, [testsStartedAt, contest?.duration, contest?.id]);

  const getQuestions = async () => {
    setLoading(true);

    const { data } = await supabase
      .from("Tests")
      .select("questions, isCompleted")
      .eq("contestId", contest?.id)
      .eq("userId", user?.id);

    const testsData = data || [];

    setDisabled(testsData[0]?.isCompleted);

    if (!testsData?.length && !testsData[0]?.questions?.length) {
      const { data: randomTestsData } = await supabase
        .rpc("get_random_quiz", {
          numberofquestions: contest?.numberOfTestsToGenerate,
        })
        .eq("category", contest?.questionsCategory);

      await supabase
        .from("Tests")
        .insert([
          {
            questions: randomTestsData,
            contestId: contest?.id,
            userId: user?.id,
          },
        ])
        .select();

      setTests(randomTestsData || []);
    } else {
      setTests(testsData[0]?.questions || []);
    }

    setLoading(false);
  };

  const toggleCompleteModalVisible = () =>
    setIsCompleteModalVisible((prev) => !prev);

  const toggleResultsModalVisible = () =>
    setIsResultsModalVisible((prev) => !prev);

  const handleSubmit = async () => {
    if (tests?.length && !disabled) {
      setLoading(true);

      const data = form.getFieldsValue();

      let numberOfCorrectAnswers = 0;
      let numberOfSkippedQuestions = 0;
      const questionsLength = Object.values(questionAnswers)?.length;
      let numberOfSolvedQuestions = questionsLength - numberOfSkippedQuestions;

      for (let x in data) {
        const isCorrect = questionAnswers[x] === data[x];

        if (isCorrect) {
          numberOfCorrectAnswers++;
        }

        if (data[x] === null || data[x] === undefined) {
          numberOfSkippedQuestions++;
        }
      }

      await supabase
        .from("Tests")
        .update({
          answers: questionAnswers,
          numberOfCorrectAnswers,
          numberOfSkippedQuestions,
          numberOfQuestions: Object.values(questionAnswers)?.length,
          numberOfSolvedQuestions,
          resultInPercent: (
            (numberOfCorrectAnswers / questionsLength) *
            100
          ).toFixed(),
          isActive: false,
          isCompleted: true,
          completedAt: new Date(),
        })
        .eq("contestId", contest?.id)
        .eq("userId", user?.id)
        .select();

      setLoading(false);

      setDisabled(true);

      setResults({
        numberOfCorrectAnswers,
        numberOfQuestions: Object.values(questionAnswers)?.length,
        resultInPercent: (
          (numberOfCorrectAnswers / questionsLength) *
          100
        ).toFixed(),
      });
      toggleResultsModalVisible();
    }
  };

  useEffect(() => {
    getQuestions();
  }, [contest?.numberOfTestsToGenerate]);

  useEffect(() => {
    if (!testsStartedAt[contest?.id]) {
      dispatch(setTestStartedAt({ [contest?.id]: new Date() }));
    }
  }, []);

  return (
    <StyledTest>
      <MainLayout containerBg="transparent" hasContainerPadding={false}>
        <Spin spinning={loading}>
          {tests?.length ? (
            <Row gutter={[16, 16]}>
              <Col span={24} lg={15}>
                <div className="test__block">
                  <Form form={form}>
                    <Row gutter={[16, 16]}>
                      {tests?.map((test, index) => (
                        <TestItem
                          id={test?.id}
                          index={index + 1}
                          key={test?.id}
                          title={test?.title}
                          answers={test?.answers}
                        />
                      ))}
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col span={24} lg={9}>
                <div className="test__block test__block--fixed">
                  <Flex vertical gap="middle">
                    <Typography.Title level={4}>
                      {contest?.title}
                    </Typography.Title>
                    <Flex wrap="wrap" align="center" gap="small">
                      {tests?.map((test, index) => (
                        <TestListItem
                          id={test?.id}
                          key={test?.id}
                          index={index + 1}
                          form={form}
                        />
                      ))}
                    </Flex>
                    <Flex gap="middle" align="center" wrap="wrap">
                      <Flex vertical align="flex-start" className="test__timer">
                        <Typography.Text>Qolgan vaqt</Typography.Text>
                        <Typography.Title level={4}>
                          <Countdown
                            date={!disabled ? contestDuration : new Date()}
                            onComplete={handleSubmit}
                          />
                        </Typography.Title>
                      </Flex>
                      <Tooltip
                        defaultOpen
                        open={disabled || !user?.id}
                        title="Test topshira olmaysiz, chunki siz allaqachon test topshirgansiz!"
                      >
                        <Button
                          type="primary"
                          className="test__button"
                          disabled={disabled || !user?.id}
                          onClick={toggleCompleteModalVisible}
                        >
                          Submit test
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </div>
              </Col>
            </Row>
          ) : (
            <Empty />
          )}
        </Spin>
      </MainLayout>
      <ConfirmModal
        onConfirm={handleSubmit}
        open={isCompleteModalVisible}
        onClose={toggleCompleteModalVisible}
      />
      <ResultsModal
        open={isResultsModalVisible}
        resultInPercent={results?.resultInPercent}
        numberOfQuestions={results?.numberOfQuestions}
        numberOfCorrectAnswers={results?.numberOfCorrectAnswers}
      />
    </StyledTest>
  );
};

export default Test;
