const paths = {
  SIGN_IN: "/sign-in/",
  CONTESTS: "/contests/",
  RESULTS: "/results/",
  TEST: "/test/",
  MAIN: "/",
  COURSES: "/kurslar/",
  ADDRESS: "/manzil/",
  ARTICLES: "/maqolalar/",
};

export default paths;
