import styled from "styled-components";
import { colors } from "../../../../theme/variables";

const StyledTestListItem = styled.div<{ selected: boolean }>`
  .item {
    width: 34px;
    height: 34px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid;
    border-color: ${({ selected }) =>
      selected ? colors.blue[100] : colors.border[100]};
    background-color: ${({ selected }) =>
      selected ? colors.blue[100] : colors.dark[100]};
  }
`;

export default StyledTestListItem;
