import styled from "styled-components";
import { colors } from "../../theme/variables";

const StyledTest = styled.div`
  .test__block {
    padding: 24px;
    border-radius: 6px;
    background-color: ${colors.dark[50]};
  }

  .test__block--fixed {
    position: fixed;
    width: 400px;
  }

  .test__timer {
    width: 120px;
  }

  .test__button {
    flex: 1;
  }

  @media (max-width: 1200px) {
    .test__block--fixed {
      width: 360px;
    }
  }
  @media (max-width: 992px) {
    .test__block--fixed {
      width: 100%;
      position: relative;
    }
  }
  @media (max-width: 576px) {
    .test__button {
      flex: auto;
      width: 100%;
    }
  }
`;

export default StyledTest;
