import type { ThemeConfig } from "antd";
import { colors } from "./variables";

export const theme: ThemeConfig = {
  token: {
    colorText: "#fff",
    fontSizeHeading2: 34,
    colorPrimary: colors.blue[100],
  },
  components: {
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 0,
      fontSize: 14,
    },
    Button: {
      borderRadius: 30,
      borderRadiusSM: 30,
      borderRadiusLG: 30,
      paddingInline: 18,
      controlHeight: 38,
      controlHeightSM: 36,
      controlHeightLG: 52,
      paddingInlineSM: 12,
      paddingInlineLG: 24,
      colorPrimary: colors.blue[100],
      defaultBg: "transparent",
      colorPrimaryBgHover: colors.blue[200],
      colorPrimaryHover: colors.blue[200],
      fontWeight: 600,
      defaultBorderColor: colors.border[100],
      borderColorDisabled: colors.border[100],
      colorTextDisabled: colors.border[100],
    },
    Layout: {
      headerHeight: 85,
      bodyBg: colors.dark[100],
      headerBg: colors.dark[100],
    },
    Menu: {
      fontSize: 15,
      darkItemBg: colors.dark[100],
      darkItemColor: "#fff",
      darkItemHoverColor: colors.blue[100],
    },
    Card: {
      lineWidth: 0,
      colorBgContainer: colors.dark[50],
    },
    Modal: {
      contentBg: colors.dark[100],
    },
    Table: {
      headerBg: colors.dark[100],
      borderColor: colors.border[100],
      colorBgContainer: colors.dark[50],
    },
    Pagination: {
      itemBg: colors.dark[50],
      itemActiveBg: colors.dark[50],
    },
  },
};
