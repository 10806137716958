import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./types";

export const initialState: AuthState = {
  user: {},
  contest: {},
  loading: false,
  testsStartedAt: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn(state, action) {
      state.user = action.payload;
    },
    setContest(state, action) {
      state.contest = action.payload;
    },
    logOut(state) {
      state.user = {};
      state.testsStartedAt = {};
    },
    setTestStartedAt(state, action) {
      state.testsStartedAt = { ...state.testsStartedAt, ...action.payload };
    },
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

export const { signIn, logOut, setContest, setTestStartedAt } = slice.actions;
